<template>
    <ul class="aside-nav">
        <li v-for="item in data" :key="item.id" @click="tabClick(item.id)">
            <div :class="item.id == currentId ? 'active li-item' : 'li-item'">
                <span :class="item.icon"></span>
                {{ item.cat_name }}
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        data: {
            default: () => {
                return []
            },
            type: Array,
        },
        currentId: {
            default: 0,
            type: [String, Number],
        },
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        tabClick(id) {
            this.$emit('tabClick', id)
        },
    },
}
</script>

<style scoped lang="scss">
.aside-nav {
    width: 200px;
    background: #fff;
    height: 100%;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0 3px 29px 0 rgba(63, 17, 17, 0.15);
    margin-right: 20px;
    overflow-y: auto;
    .li-item {
        height: 90px;
        font-size: 18px;
        line-height: 90px;
        color: rgba(51, 73, 209, 1);
        text-align: center;
        display: flex;
        align-items: center;
        padding-left: 50px;
        cursor: pointer;
        &.active {
            & > span:before {
                color: #fff;
            }
            box-shadow: 0 3px 29px 0 rgba(46, 98, 226, 0.58);
            color: #fff;
            background: linear-gradient(90.71deg, #162583 2.34%, #374fdc 99.47%);
        }
        span {
            margin-right: 15px;
        }
    }
}
</style>
