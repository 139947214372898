<template>
    <el-dialog :before-close="handleClose" class="login-card" title="提示" :visible.sync="loginIsShow" width="498px">
        <h2>
            <img src="@/assets/img/logo-s.png" alt="" />
            登录
        </h2>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item prop="phone">
                <el-input placeholder="请输入手机号" v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item prop="passwords">
                <el-input placeholder="请输入密码" v-model="ruleForm.passwords"></el-input>
            </el-form-item>
        </el-form>
        <el-button :loading="loading" type="primary" @click="confirm">快速登陆</el-button>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            ruleForm: {
                passwords: '',
                phone: '',
            },
            rules: {
                passwords: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
            },
            loading: false,
        }
    },
    computed: {
        loginIsShow() {
            return !this.$store.getters.loggedIn && this.$store.state.loginInfo.diaIsShow
        },
    },
    methods: {
        handleClose() {
            this.$store.commit('SET_DIA_IS_SHOW', false)
        },
        cancel() {},
        confirm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    this.$store
                        .dispatch('login', {
                            login: this.ruleForm.phone,
                            password: this.ruleForm.passwords,
                        })
                        .then(() => {
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
    },
}
</script>

<style scoped lang="scss">
.login-card::v-deep {
    .el-dialog {
        border-radius: 10px;
    }
    .el-dialog__title {
        display: none;
    }
    .el-dialog__headerbtn .el-dialog__close {
        font-size: 36px;
        color: #000;
        font-weight: bold;
    }
    border-radius: 8px;
    h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
        color: #000;
        margin-bottom: 30px;
        img {
            width: 50px;
            margin-right: 10px;
        }
    }
    .el-dialog__body {
        padding: 30px 40px !important;
    }
    .el-form {
        .el-form-item__content {
            margin-left: 0 !important;
        }
        .el-input__inner {
            height: 68px;
            border-radius: 34px;
            box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.05);
            text-indent: 20px;
            &::placeholder {
                text-indent: 20px;
                font-size: 16px;
            }
        }
    }
    .el-button {
        width: 100%;
        background: #2e62e2;
        height: 58px;
        border-radius: 39px;
        font-size: 18px;
        margin-bottom: 30px;
    }
}
</style>
