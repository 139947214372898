import Vue from 'vue'
import Vuex from 'vuex'
import { API_BASE_URL } from '@/assets/config'
import axiosInstance from '@/service/request/http'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loginInfo: {
            diaIsShow: false,
            userInfo: null,
        },
    },
    getters: {
        loggedIn(state) {
            return !!state.loginInfo.userInfo
        },
    },
    mutations: {
        SET_INFO: (state, payload) => {
            state.loginInfo.userInfo = payload
        },
        SET_DIA_IS_SHOW: (state, payload) => {
            state.loginInfo.diaIsShow = payload
        },
    },
    actions: {
        init({ dispatch }) {
            dispatch('getUserInfo')
        },
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post(
                        '/auth/login',
                        { user: payload },
                        {
                            baseURL: API_BASE_URL.api,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                        localStorage.setItem('TOKEN', res.data.token)
                        commit('SET_INFO', res.data.user)
                        // dispatch('getUserInfo')
                        // console.log(res, 'login----------res')
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getUserInfo({ commit, dispatch }) {
            if (!localStorage.getItem('TOKEN')) {
                return
            }
            return new Promise(() => {
                axiosInstance
                    .get('/accounts/profile', {
                        baseURL: API_BASE_URL.api,
                    })
                    .then((res) => {
                        commit('SET_INFO', res.data)
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            dispatch('logout')
                            commit('SET_DIA_IS_SHOW', true)
                        }
                    })
            })
        },
        logout({ commit }) {
            localStorage.removeItem('TOKEN')
            commit('SET_INFO', null)
        },
    },
    modules: {},
})
