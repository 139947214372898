import HeaderNav from '@/components/HeaderNav.vue'
import FooterNav from '@/components/FooterNav.vue'
import AsideNav from '@/components/AsideNav.vue'
import TextComparison from '@/components/TextComparison.vue'
import LoginCard from '@/components/LoginCard.vue'
import ReportDia from '@/components/ReportDia.vue'

const install = function (Vue) {
    // 注册全局组件
    Vue.component('HeaderNav', HeaderNav)
    Vue.component('FooterNav', FooterNav)
    Vue.component('AsideNav', AsideNav)
    Vue.component('TextComparison', TextComparison)
    Vue.component('LoginCard', LoginCard)
    Vue.component('ReportDia', ReportDia)
}

export default {
    install,
}
