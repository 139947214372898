import axios from 'axios'
import Vue from 'vue'
const vm = new Vue({})
axios.defaults.timeout = 50000

// 表示跨域请求时是否需要使用凭证
axios.defaults.withCredentials = false
const axiosInstance = axios.create({})
// 添加请求拦截器
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('TOKEN')
        if (token) {
            config.headers.Authorization = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config
    },
    function (error) {
        // 错误处理
        return Promise.reject(error)
    }
)
// 添加响应拦截器
axiosInstance.interceptors.response.use(
    function (response) {
        // 响应处理
        return response.data
    },
    function (error) {
        let msg = '网络异常，请重试'
        if (error.response) {
            const data = error?.response?.data || {}
            msg = data.error?.message
        } else {
            msg = '网络异常，请重试'
        }
        vm.$message.warning(msg)
        throw error
    }
)

export default axiosInstance
