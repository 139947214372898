<template>
    <div class="header-nav">
        <div class="content">
            <img class="logo" src="@/assets/img/logo.png" />
            <div class="right">
                <el-menu :default-active="onRoutes" router class="el-menu-demo" mode="horizontal">
                    <el-menu-item index="/">首页</el-menu-item>
                    <el-menu-item index="/lookWrite">看打练习</el-menu-item>
                    <el-menu-item index="/listenWrite">听打练习</el-menu-item>
                </el-menu>
                <div class="user-info">
                    <el-dropdown @command="routerTo" v-if="login" class="login">
                        <span class="el-dropdown-link">{{ mobile }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="a">练习记录</el-dropdown-item>
                            <el-dropdown-item command="b" divided>退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div v-else class="logout" @click="toLogIn"><i class="icon01"></i>快速登陆</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderNav',
    data() {
        return {
            activeIndex: '0',
        }
    },
    computed: {
        onRoutes() {
            const route = this.$route
            const { meta, path } = route
            // 在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
            // meta中 有activeMenu 字段的子页面，都会显示高亮
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        login() {
            return this.$store.getters.loggedIn
        },
        userInfo() {
            return this.$store.state.loginInfo.userInfo
        },
        mobile() {
            const phoneNumber = this.userInfo.mobile
            // 检查手机号是否合法，这里简单判断了一下，您可能需要根据实际需求进行更严格的检查
            if (typeof phoneNumber !== 'string' || phoneNumber.length !== 11) {
                console.error('Invalid phone number')
                return phoneNumber
            }
            // 替换中间四位为星号
            const maskedPhoneNumber = phoneNumber.substring(0, 3) + '****' + phoneNumber.substring(7)

            return maskedPhoneNumber
        },
    },
    methods: {
        routerTo(e) {
            switch (e) {
                case 'a':
                    this.$router.push('/practiceRecord')
                    break
                case 'b':
                    this.logout()
                    break
                default:
                    break
            }
        },
        logout() {
            this.$store.dispatch('logout')
        },
        toLogIn() {
            this.$store.commit('SET_DIA_IS_SHOW', true)
        },
    },
    watch: {},
}
</script>

<style scoped lang="scss">
.header-nav {
    width: 100%;
    background: #fff;
    .content {
        width: 1200px;
        margin: 0 auto;
        height: 100px;
        display: flex;
        align-items: center;
        img.logo {
            width: 240px;
        }
        .right {
            display: flex;
            margin-left: 200px;
            flex: 1;
            align-items: center;
            justify-content: space-evenly;
            .el-menu-item {
                padding: 0 !important;
                margin: 0 60px !important;
                font-size: 16px;
                &.is-active {
                    color: #14237e;
                    border-bottom: 4px solid #14237e;
                    font-weight: bold;
                }
            }
            .el-menu.el-menu--horizontal {
                border-bottom: none;
            }
            .user-info {
                cursor: pointer;
                .logout {
                    height: 44px;
                    box-sizing: border-box;
                    padding: 0 16px;
                    box-shadow: 0 3px 9px 0 rgba(46, 98, 226, 0.2);
                    border-radius: 44px;
                    border: solid 1px rgba(46, 98, 226, 0.3);
                    cursor: pointer;
                    color: #2e62e2;
                    line-height: 42px;
                    .icon01 {
                        display: inline-block;
                        width: 20px;
                        height: 42px;
                        margin-right: 8px;
                        background: url(../assets/img/icon01.png) no-repeat center;
                        background-size: contain;
                        font-style: normal;
                        vertical-align: middle;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .login {
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.el-dropdown-menu {
    border-radius: 12px;
    &__item {
        width: 110px;
        text-align: center;
        &:not(.is-disabled):hover {
            background-color: #fff;
            color: #14237e;
        }
    }
}
</style>
