const handelDateForList = {
    bind(el, binding) {
        // 获取标准年月日
        const time = binding.value * 1000
        const date = new Date(time)
        const year = date.getFullYear()
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        const tip = year + '-' + month + '-' + day + '  ' + hour + ':' + min + ':' + second
        el.innerHTML = tip
    },
}
export default handelDateForList
