<template>
    <div class="text-comparison">
        <div v-for="(item, index) in content" :key="index" class="text-comparison-item" :class="autofocusIndex === index ? 'active' : ''">
            <div class="example">
                <span :key="eIndex" v-for="(e, eIndex) in item.content" :class="e.state === 1 ? 'right' : e.state === 2 ? 'error' : ''">{{
                    e.text
                }}</span>
            </div>
            <div class="write">
                <el-input
                    :ref="'saveTagInput' + index"
                    :disabled="disabled || index > maxActive"
                    @copy.native="notAllow"
                    @cut.native="notAllow"
                    @paste.native="notAllow"
                    @keydown.native="handleKeydown($event, item.inputValue)"
                    class="textInput"
                    @input="handelInput($event, item, index)"
                    :debounce="1000"
                    v-model="item.inputValue"
                    @blur.native="handelBlur"
                    placeholder=""
                ></el-input>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data', 'disabled', 'defaultInput'],
    data() {
        return {
            content: [],
            maxActive: 0,
            rightNum: 0,
            wrongNum: 0,
            autofocusIndex: 0,
        }
    },
    mounted() {
        this.content = []
        this.data.forEach((item, index) => {
            const arr = []
            let inputValue = ''
            this.defaultInput && this.defaultInput[index] && (inputValue = this.defaultInput[index])
            item.split('').forEach((e) => {
                arr.push({ text: e, state: null })
            })
            this.content.push({ content: arr, inputValue })
            arr.forEach((e, i) => {
                if (e.text === inputValue.split('')[i]) {
                    arr[i].state = 1
                } else if (inputValue.split('')[i]) {
                    arr[i].state = 2
                } else {
                    arr[i].state = 0
                }
            })
        })
    },
    methods: {
        getRightWrongNum() {
            let rightNum = 0
            let wrongNum = 0
            const inputContent = []
            this.content.forEach((item) => {
                item.inputValue && item.inputValue.length > 0 && inputContent.push(item.inputValue)
                item.content.map((e) => {
                    if (e.state === 1) {
                        rightNum += 1
                    } else if (e.state === 2) {
                        wrongNum += 1
                    }
                })
            })
            return { wrongNum, rightNum, inputContent }
        },
        maxActiveInput(e) {
            if (e === 'add') {
                this.maxActive += 1
            } else if (e === 'reduce') {
                this.maxActive -= 1
            }
        },
        handelInput(item, row, rowIndex) {
            if (item.length >= row.content.length) {
                // console.log('到尾巴了', rowIndex)
                // 忘记是干啥的了
                // !!item.substring(0, row.content.length) && (item = item.substring(0, row.content.length))
                // 下一行自动获取焦点
                this.autofocusIndex = rowIndex + 1
                const refName = 'saveTagInput' + this.autofocusIndex
                const that = this
                this.$nextTick(() => {
                    that.$refs[refName][0].focus()
                })
                // 溢出文字自动填入下一行
                const text = item.substring(row.content.length)
                this.content[this.autofocusIndex].inputValue = text
                // 计算可输入的最大行数
                this.maxActiveInput('add')
            }
            if (0 === item.length && rowIndex !== 0) {
                // console.log('到顶了', rowIndex)
                // 上一行自动获取焦点
                this.autofocusIndex = rowIndex - 1
                const refName = 'saveTagInput' + this.autofocusIndex
                const that = this
                this.$nextTick(() => {
                    that.$refs[refName][0].focus()
                })
                // 计算可输入的最大行数
                this.maxActiveInput('reduce')
            }
            row.content.forEach((e, i) => {
                item.split('')[i]
                if (e.text === item.split('')[i]) {
                    row.content[i].state = 1
                } else if (item.split('')[i]) {
                    row.content[i].state = 2
                } else {
                    row.content[i].state = 0
                }
            })
            const filteredData = row.content.filter((item) => item.state === 1 || item.state === 2)
            const count = filteredData.length
            this.$emit('totalCountChange', count)
        },
        handleKeydown(e, inputValue) {
            if (e.key === 'Backspace' && inputValue) {
                this.$emit('handleKeydown')
            }
        },
        notAllow(e) {
            e.preventDefault()
        },
    },
    // watch: {
    //     input_content(nValue) {
    //         console.log(nValue, 'nValue')
    //     },
    // },
}
</script>

<style scoped lang="scss">
.text-comparison-item {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 15px 0 10px 30px;
    background: #fff;
    border: 1px solid #ececec;
    border-radius: 6px;
    &.active {
        box-shadow: 0 3px 29px 0 rgba(220, 220, 220, 0.95);
    }
    .example {
        text-align: left;
        margin-bottom: 10px;
        .error {
            color: red;
        }
        .right {
            color: green;
        }
    }
    .write::v-deep {
        input {
            border: none;
        }
        .el-input__inner {
            padding: 0 !important;
            font-size: 16px;
        }
        .el-input.is-disabled .el-input__inner {
            background: #fff !important;
        }
    }
}
</style>
