const dateFormat = {
    bind(el, binding) {
        const seconds = binding.value
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
        el.innerHTML = formattedTime
    },
    update(el, binding) {
        const seconds = binding.value
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
        el.innerHTML = formattedTime
    },
}
export default dateFormat
