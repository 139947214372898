<template>
    <!-- 根组件 -->
    <div class="layout-page">
        <HeaderNav></HeaderNav>
        <div class="layout-content">
            <router-view></router-view>
        </div>
        <FooterNav></FooterNav>
        <LoginCard></LoginCard>
    </div>
</template>
<script>
export default {
    name: 'LayoutPage',
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('init')
    },
}
</script>
<style scoped lang="scss">
.layout-content {
    & > div {
        box-sizing: border-box;
    }
}
</style>
