<template>
    <el-dialog class="report-dia" title="练习报告" :visible="dialogVisible" width="726px" :before-close="handleClose">
        <div class="content">
            <div class="item">
                <div class="label">练习用时</div>
                <div class="value" v-dateFormat="data.time_used"></div>
            </div>
            <div class="item">
                <div class="label">正确率</div>
                <div class="value">{{ data.accuracy }}%</div>
            </div>
            <div class="item">
                <div class="label">打字速度</div>
                <div class="value">{{ data.typing_speed }}字/分</div>
            </div>
            <div class="item">
                <div class="label">退格次数</div>
                <div class="value">{{ data.backspace_count }}</div>
            </div>
            <div class="item">
                <div class="label">错误字数</div>
                <div class="value">{{ data.wrong_words }}</div>
            </div>
        </div>
        <div class="btn">
            <el-button type="info" @click="toDetail">查看答案</el-button>
            <el-button type="info" @click="recordAgain">再次练习</el-button>
            <el-button type="primary" @click="moreRecord">其他练习</el-button>
        </div>
        <div class="info">
            <div class="item">
                <div class="label">科目类别：</div>
                <div class="value">{{ data.event_type | handelType }}</div>
            </div>
            <div class="item">
                <div class="label">练习试卷：</div>
                <div class="value">{{ data.title }}</div>
            </div>
            <div class="item">
                <div class="label">练习时间：</div>
                <div class="value" v-handelDateForList="data.time / 1000"></div>
            </div>
            <div class="item" v-if="data.event_type === 'listen_typings'">
                <div class="label">音频速度：</div>
                <div class="value">{{ data.speed }}字/分钟</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['dialogVisible', 'data'],
    data() {
        return {}
    },
    filters: {
        handelType(e) {
            let str = ''
            // eslint-disable-next-line default-case
            switch (e) {
                case 'listen_typings': {
                    str = '听打'
                    break
                }
                case 'look_typings': {
                    str = '看打'
                    break
                }
            }
            return str
        },
    },
    computed: {},
    methods: {
        handleClose() {
            this.$emit('close')
        },
        recordAgain() {
            this.$emit('recordAgain')
        },
        toDetail() {
            this.$emit('checkAnswer')
        },
        moreRecord() {
            if (this.data.event_type === 'listen_typings') {
                this.$router.push('/listenWrite')
            } else if (this.data.event_type === 'look_typings') {
                this.$router.push('/lookWrite')
            }
        },
    },
}
</script>

<style scoped lang="scss">
.report-dia::v-deep {
    .el-dialog {
        height: 387px;
        border-radius: 10px;
        background: url('../assets/img/login-card.png') no-repeat;
        background-size: 100%;
        .el-dialog__header {
            padding: 35px 20px 10px;
        }
        .el-dialog__close {
            font-size: 36px;
            color: #000;
            font-weight: bold;
        }
        .el-dialog__title {
            font-size: 32px;
            font-weight: bold;
        }
        .content,
        .info {
            width: 582px;
            height: 82px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;
            .item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                .label {
                    font-size: 18px;
                    color: #666666;
                }
                .value {
                    font-size: 20px;
                    font-weight: bold;
                    color: #2e62e2;
                }
                &:nth-child(2) {
                    color: #00bc29;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    .value {
                        color: #ff7222;
                    }
                }
            }
        }
        .btn {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            .el-button {
                width: 178px;
                height: 54px;
                line-height: 54px;
                text-align: center;
                padding: 0px;
                font-size: 18px;
                border: none;
                border-radius: 55px;
                &.el-button--info {
                    background: #f5f5f5;
                    color: #aaaaaa;
                }
                &.el-button--primary {
                    background: #2e62e2;
                }
                &:nth-child(2) {
                    margin-left: -70px;
                }
            }
        }
        .info {
            margin: 20px auto 0;
            height: 20px;
            .item {
                display: flex;
                flex-direction: row;
                .label {
                    font-size: 12px;
                }
                .value {
                    font-size: 12px;
                    color: #666666;
                    font-weight: normal;
                }
                &:nth-child(4) {
                    .value {
                        color: #666;
                    }
                }
            }
        }
    }
}
</style>
