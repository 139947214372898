<template>
    <div class="footer-nav">
        <div class="content">
            <div class="content-l">
                <img src="@/assets/img/footer-logo.png" alt="" />
                <div class="links">
                    <router-link to="/">巨书首页</router-link>
                    <router-link to="/">关于巨书</router-link>
                </div>
            </div>
            <div class="content-r">
                <div class="item">
                    <img src="../assets/img/code1.jpg" alt="" />
                    <div class="label">扫码关注巨书公众号</div>
                </div>
                <div class="item">
                    <img src="../assets/img/code2.png" alt="" />
                    <div class="label">扫码添加企微</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterBar',
    data() {
        return {}
    },
    methods: {},
    watch: {},
}
</script>

<style scoped lang="scss">
.footer-nav {
    height: 188px;
    width: 100%;
    background: #000;

    .content {
        height: 100%;
        margin: 0 auto;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content-l {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        & > img {
            width: 190px;
            display: block;
            margin-bottom: 20px;
        }
        .links a {
            color: #fff;
            margin-right: 30px;
            font-size: 14px;
            text-decoration: none;
        }
    }
    .content-r {
        display: flex;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            img {
                width: 100px;
                margin-bottom: 10px;
            }
            .label {
                font-size: 12px;
                color: #fff;
            }
        }
    }
}
</style>
