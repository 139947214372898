import handelDateForList from './handelDateForList'
import dateFormat from './dateFormat'
// 将自定义指令包装为对象
const directives = {
    handelDateForList,
    dateFormat,
}
//将对象循环注册在导出
export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key])
        })
    },
}
