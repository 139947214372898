import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/views/LayoutPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: layout,
        meta: {
            title: '首页',
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/HomePage.vue'),
            },
            {
                path: '/listenWrite',
                name: 'ListenWrite',
                component: () => import('@/views/ListenWrite.vue'),
                children: [
                    {
                        path: '/',
                        name: 'ListPage',
                        component: () => import('@/views/ListPage.vue'),
                    },
                    {
                        path: 'speedRecord',
                        name: 'SpeedRecord2',
                        component: () => import('@/views/SpeedRecord2.vue'),
                        meta: {
                            activeMenu: '/listenWrite',
                        },
                    },
                ],
            },
            {
                path: '/lookWrite',
                name: 'LookWrite',
                component: () => import('@/views/LookWrite.vue'),
                children: [
                    {
                        path: '/',
                        name: 'ListPage',
                        component: () => import('@/views/ListPage.vue'),
                    },
                    {
                        path: 'speedRecord',
                        name: 'SpeedRecord',
                        component: () => import('@/views/SpeedRecord.vue'),
                        meta: {
                            activeMenu: '/lookWrite',
                        },
                    },
                ],
            },
            {
                path: '/practiceRecord',
                name: 'PracticeRecord',
                component: () => import('@/views/PracticeRecord.vue'),
            },
        ],
    },
]
//解决报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
