<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    mounted() {},
}
</script>

<style lang="scss">
#app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #f5f5f5;
}
.myScrollBar,
.el-tabs__content {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}
.myScrollBar::-webkit-scrollbar,
.el-tabs__content::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}
.myScrollBar::-webkit-scrollbar-track,
.el-tabs__content::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
}
.myScrollBar::-webkit-scrollbar-thumb,
.el-tabs__content::-webkit-scrollbar-thumb {
    background: #c7c6c6;
}
.myScrollBar::-webkit-scrollbar-thumb:hover,
.el-tabs__content::-webkit-scrollbar-thumb:hover {
    background: #adadad;
}

//火狐
.myScrollBar {
    scrollbar-color: #c7c6c6 rgb(239, 239, 239);
    scrollbar-width: thin;
    -ms-overflow-style: none;
}
</style>
