import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/font.css'
import './assets/icon/style.css'

import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'

//一些全局使用的自定义指令
import Directives from '@/service/directive/index'

import axiosInstance from '@/service/request/http'
Vue.prototype.$axios = axiosInstance

import '@/assets/css/index.scss'

import 'core-js/stable'
import 'regenerator-runtime/runtime'
Vue.use(Directives)

import baseComponent from './components/install'
Vue.use(baseComponent)

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
